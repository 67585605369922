









































































































































import Vue from "vue";
import { Category } from "@/store/categories/types";
import { Utils } from "@/utils/";

export default Vue.extend({
  name: "Categories",

  computed: {
    _pagination: {
      get: function () {
        return this.$store.state.categories.pagination;
      },
      set: function (v) {
        this.$store.state.categories.pagination = v;
      },
    },
    categories: function () {
      return this.$store.state.categories.categories;
    },
    loading: function () {
      return this.$store.state.categories.loading;
    },
    activeShopToken: function () {
      return this.$store.state.shops.activeShopToken;
    },
  },

  watch: {
    activeShopToken: {
      handler() {
        this.$store.dispatch("categories/fetchCategories", this.showDisabled);
      },
      deep: true,
    },
  },

  data: function () {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      showDisabled: false,
      category: {},
      edit: false,
      dialog: false,
      action: "",
      thisCategory: {},
      searchVal: "",
      headers: [
        { text: "Name", value: "name", sortable: false },
        {
          text: "Short Description",
          value: "shortDescription",
          sortable: false,
        },
        { text: "Position", value: "position" },
        { text: "Active", value: "active", sortable: false },
        { text: "", value: "edit", sortable: false },
      ],
    };
  },

  mounted: async function () {
    // Set the default paging
    this.$store.dispatch("categories/paginate", {
      ...this.$store.state.categories.pagination,
    });
    this.$store.dispatch("categories/fetchCategories", this.showDisabled);
  },

  methods: {
    onShowDisabled(isDisabled: boolean) {
      this.$store.dispatch("categories/fetchCategories", isDisabled);
    },
    onCategoryAdd() {
      if ((this.$refs.form as any).validate()) {
        this.$store.dispatch("categories/createCategory", this.category);

        // reset form
        this.category = {};
        (this.$refs.form as any).reset();
      }
    },
    formatColumn(header: any, item: any) {
      return Utils.formatColumn(header, item);
    },
    confirmDialog(thisCategory: Category, disable: boolean) {
      this.dialog = true;
      this.thisCategory = thisCategory;
      this.action = disable ? "Disable" : "Enable";
    },
    doAction(thisCategory: Category) {
      this.dialog = false;
      this.$store.dispatch("categories/disableCategory", {
        category: thisCategory,
        showDisabled: this.showDisabled,
      });
    },
    editCategory(thisCategory: Category) {
      this.category = thisCategory;
      this.edit = true;
    },
    onCategoryUpdate() {
      if ((this.$refs.form as any).validate()) {
        this.$store.dispatch("categories/updateCategory", this.category);

        // reset form
        this.edit = false;
        this.category = {};
        (this.$refs.form as any).reset();
      }
    },
    cancelUpdate() {
      this.edit = false;
      this.category = {};
      (this.$refs.form as any).reset();
      // reload to cancel non committed changes to model

      this.$store.dispatch("categories/fetchCategories", this.showDisabled);
    },
    save(item: Category) {
      this.$store.dispatch("categories/updateCategory", item);
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
  },
});
